@import '../../assets/scss/variables.scss';

.modalContainer {
    width: 350px;
    max-width: 90vw;
    padding: $spacing3;

    .contentWrapper {
        .languages {
            display: flex;
            flex-direction: column;
            gap: $spacing1;
            margin-top: $spacing2;

            button {
                display: block;
                width: 100%;
                text-align: left;
                border: none;
                background: #f5f5f5;
                border-radius: 10px;
                padding: $spacing2;
                cursor: pointer;

                &:hover {
                    background-color: #eee;
                }

                &.active {
                    background-color: $primary;
                    color: #fff;
                    font-weight: bold;
                }
            }
        }
    }
}
