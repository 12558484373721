.organisation-layout {
  .top-bar {
    height: 100px;
    display: flex;
    align-items: center;
    background: #fff;

    .container {
      display: flex;
      flex-direction: row;
      justify-content: space-between;

      .logo {
        img {
          max-height: 42px;
        }
      }
    }
  }

  .container {
    @media (min-width: 1400px) {
      max-width: 1600px;
    }
  }

  > .container {
    > .page-content {
      padding: 32px 0;
    }
  }
}
