@import './variables.scss';

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid $primary;
  border-radius: 50%;
  width: 64px;
  min-width: 64px;
  height: 64px;
  min-height: 64px;
  animation: spin 0.7s linear infinite;
  margin: auto;

  &-sm {
    height: 32px;
    width: 32px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
