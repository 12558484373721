.organisation-search-wrapper {
  height: calc(100vh - 100px - 64px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .organisation-search-card {
    width: 100%;
    max-width: 1200px;
    background: rgba(0, 0, 0, 0.15);
    border-radius: 25px;
    padding: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .logo {
      max-width: 100%;
      max-height: 64px;
    }

    .search-form {
      width: 100%;
      margin-top: 64px;
    }
  }

  @media screen and (max-width: 767px) {
    .organisation-search-card {
      padding: 32px;
    }
  }
}
