@import '../../assets/scss/variables.scss';

.button-root {
  border-radius: 10px;
  outline: none;
  border: none;
  cursor: pointer;
  padding: 0;
  color: #fff;
  background: $primary;
  transition: all 0.2s;

  // Sizes
  &.button--large {
    background: $secondary;
    padding: 14px;
    font-weight: bold;
    font-size: 25px;
  }

  &.button--medium {
    padding: 10px;
    font-size: 16px;
    border-radius: 8px;
  }

  &.button--small {
    padding: 5px;
    font-size: 18px;
    border-radius: 5px;
    min-width: 160px;
  }

  // Colors
  &.button--bg-primary {
    background: $primary;

    &:hover {
      background: lighten($primary, 10);
    }
  }

  &.button--bg-secondary {
    background: $secondary;

    &:hover {
      background: lighten($secondary, 10);
    }
  }

  &.button--bg-tertiary {
    background: #949494;

    &:hover {
      background: #bbb;
    }
  }

  &[disabled] {
    opacity: 0.7;
    cursor: not-allowed;
  }

  &.button--loading {
    position: relative;
    color: transparent;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border: 2px solid $primary;
      border-top: 2px solid #fff;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      animation: spin 0.7s linear infinite;
      margin: auto;
    }
  }
}

button:focus {
  outline: none !important;
}
