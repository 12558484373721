@import '../../assets/scss/variables.scss';

.paginationWrapper {
  display: flex;
  justify-content: flex-end;
}

.pagination {
  .moreDots {
    display: inline-block;
    padding: 5px 16px;
    color: #999;
    font-size: 18px;
    line-height: 1;
  }
}

.paginationButton {
  padding: 5px 16px;
  outline: none;
  border: none;
  color: #333;
  cursor: pointer;
  border-radius: 5px;
  margin: 0 5px;
  background: transparent;

  &:hover {
    background-color: #eee;
  }

  &Active,
  &Previous,
  &Next {
    background-color: $primary;
    color: #fff;

    &:hover {
      background: lighten($primary, 10);
    }
  }

  &:disabled,
  &[disabled] {
    pointer-events: none;
    background-color: #e5e5e5;
    color: $textColor;
  }
}
