.organisation-search-results {
  .table-wrapper {
    background: #fff;
    border: 1px solid #d2d2d2;
    border-radius: 5px;
    padding: 16px;
  }

  .action-button {
    border: none;
    background: none;
    cursor: pointer;

    &:hover {
      img {
        opacity: 0.5;
      }
    }

    img {
      width: 26px;
    }
  }

  .add-pet-page,
  .user-profile-page {
    margin: 0;
  }
}

.table-wrapper {
  background: #fff !important;
}
