@import '../../assets/scss/variables.scss';

.auth-page {
    padding: $spacing3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-size: cover;
    background-image: url('../../assets/images/auth-background.png');

    .auth-card-wrapper {
        border-radius: 20px;
        width: 750px;
        max-width: 100%;

        .auth-card {
            display: flex;
            flex-direction: column;
            border-radius: 20px;
            background-color: $primary;
            padding: $spacing4;

            .logo-wrapper {
                text-align: center;

                .logo-img {
                    max-width: 100%;
                    max-height: 80px;
                }

                .auth-title {
                    color: #fff;
                    text-transform: uppercase;
                    font-size: 28px;
                    margin-top: 8px;
                }
            }

            .content-wrapper {
                margin-top: 80px;
                margin-bottom: 80px;

                .input-wrapper {
                    margin-top: $spacing2;
                }

                .row {
                    align-items: center;
                    margin-top: 16px;
                }

                .footer-wrapper {
                    display: flex;
                    justify-content: space-between;
                    margin-top: $spacing2;
                }

                .button {
                    font-size: 16px;
                    cursor: pointer;
                    border: none;
                    outline: none;
                    background: none;
                    color: #35c0ed;
                    font-weight: 700;
                    transition: all 0.2s;

                    &.resend-button {
                        padding-left: 0;
                        margin-top: 8px;
                    }

                    &:hover {
                        color: #009ed3;
                    }
                }

                .submit-button {
                    width: 100%;
                    margin-top: 16px;
                }

                .forgot-password-text {
                    color: #fff;
                }
            }

            .reset-password-step-title {
                color: #fff;
                font-size: 18px;
            }
        }
    }

    .change-language {
        margin-top: $spacing3;

        a {
            display: block;
            color: $textColor;
            text-align: center;
            line-height: 1.3;

            span {
                display: block;
                font-size: 13px;
                opacity: 0.8;
            }
        }
    }

    .error-message {
        font-size: 14px;
        color: red;
        margin-left: 10px;
    }

    .checkbox-container {
        display: flex;
        align-items: center;

        .tos-text {
            color: #fff;

            .tos-button {
                background: none;
                border: none;
                color: #fff;
                text-decoration: underline;
                padding-left: 0;
                cursor: pointer;
                transition: all 0.2s;

                &:hover {
                    color: lighten($secondary, 10);
                }
            }
        }
    }
}

@media (max-width: 768px) {
    .row {
        &.row-auth-register {
            justify-content: center;
        }
    }
}

@media (min-width: 768px) {
    .row {
        &.row-auth-register {
            justify-content: space-between;
        }
    }
}
