@import '../../assets/scss/variables.scss';

.header-tab-button {
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  padding-bottom: $spacing1;
  font-size: 24px;
  color: $textColor;
  min-width: 160px;
  border-bottom: 5px solid transparent;
  transition: background 0.2s;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &:disabled {
    cursor: not-allowed;
  }

  &:hover {
    background: #eaf3f7;

    &:disabled {
      background-color: #fff;
    }
  }

  &.header-tab-button-active {
    border-bottom: 5px solid #35c0ed;
  }
}
