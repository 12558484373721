@import '../../assets/scss/variables.scss';

.root {
    display: flex;
    gap: 1px;

    button {
        border: none;
        background: #f5f5f5;
        padding: $spacing2 $spacing3;
        font-size: 0.9rem;
        line-height: 1;
        cursor: pointer;

        &:hover {
            background-color: #eee;
        }

        &.current {
            background-color: $primary;
            color: #fff;
        }

        &.error {
            box-shadow: inset 0 0 0 1px red;
        }

        &:first-child {
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
        }

        &:last-child {
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
        }
    }
}
