@import '../../assets/scss/variables.scss';

.rte-wrapper {
  .rte-editor-wrapper {
    border-radius: 5px;
    border: 1px solid #d2d2d2;
  }

  .rte-toolbar {
    border: none;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom: 1px solid #eee;
  }

  .rte-editor {
    min-height: 300px;
    max-height: 600px;

    .DraftEditor-editorContainer {
      padding: 0 16px;
    }
  }

  .error-message {
    color: red;
    font-size: 12px;
    display: inline-block;
    margin-left: $spacing1;
    font-size: 14px;
  }
}
