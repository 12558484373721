@import '../../assets/scss/variables.scss';

.dropdown {
  position: relative;

  button {
    outline: none;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }

  .children {
    overflow: auto;
    max-height: 150px;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.15);
    position: absolute;
    top: 100%;
    right: 0;
    z-index: 10;
  }

  ::-webkit-scrollbar {
    width: 13px;
    height: 18px;
  }
  ::-webkit-scrollbar-thumb {
    background: #888;
    height: 6px;
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
  }
  ::-webkit-scrollbar-button {
    width: 0;
    height: 0;
    display: none;
  }
  ::-webkit-scrollbar-corner {
    background-color: transparent;
  }
  ::-webkit-scrollbar-track {
    border: 4px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    border-radius: 7px;
    background-color: #eaeaea;
    margin: 5px 5px;
  }
}
