@import '../../assets/scss/variables.scss';

.user-profile-page {
  margin: $spacing3;

  .user-profile-card {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 20px;

    .card-header {
      padding-left: $spacing3;
      padding-right: $spacing3;
      padding-top: $spacing2;
    }

    .card-add-user-info {
      padding: $spacing3;
      border-top: 1px solid #eee;

      .user-avatar-container {
        height: 220px;
        width: 220px;
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $spacing2;
        cursor: pointer;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 10px;

        &.no-avatar {
          background-size: 90px;
        }
      }

      .add-avatar-button {
        outline: none;
        border: none;
        background: #ffad01;
        width: 100%;
        height: 45px;
        border-radius: 5px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .text-input {
        margin-bottom: $spacing1;
      }

      .date-input {
        margin-bottom: $spacing1;
      }

      .title-container {
        margin-top: $spacing3;
        margin-bottom: $spacing1;
      }

      .checkbox-link {
        font-weight: 700;
        color: #35c0ed;
      }
    }
  }

  .card-pet-ownership {
    padding: $spacing3;
    background: #fff;
    border-top: 1px solid #eee;
    border-bottom-right-radius: 20px;
    border-bottom-left-radius: 20px;

    .action-button {
      margin-left: 10px;

      &:first-child {
        margin-left: 0;
      }

      &:hover {
        img {
          opacity: 0.5;
        }
      }

      img {
        width: 26px;
      }
    }
  }
}
