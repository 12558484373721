@import '../../assets/scss/variables.scss';

.add-pet-page {
  margin: $spacing3;

  .input-private {
    min-width: auto;
  }

  .add-pet-details-card {
    background: #ffffff;
    box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.15);
    border-radius: 20px;

    .card-header {
      border-bottom: 1px solid #eee;
      padding-left: $spacing3;
      padding-right: $spacing3;
      padding-top: $spacing2;

      .modify-pet-button {
        &.remove-pet-button {
          background: #35c0ed;
        }
      }
    }

    .card-add-pet-info {
      padding: $spacing3;

      .error-message {
        display: block;
        color: red;
        margin-bottom: 25px;
      }

      .pet-avatar-container {
        height: 220px;
        width: 220px;
        border: 1px solid #d2d2d2;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: $spacing2;
        cursor: pointer;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        border-radius: 10px;

        &.no-avatar {
          background-size: 90px;
        }
      }

      .add-avatar-button {
        outline: none;
        border: none;
        background: #ffad01;
        width: 100%;
        height: 45px;
        border-radius: 5px;
        color: #fff;
        font-size: 18px;
        cursor: pointer;
      }

      .external-id-container {
        display: flex;
        justify-content: center;

        .external-id-text {
          font-weight: 700;
          font-size: 18px;
        }
      }

      .text-input {
        margin-bottom: $spacing1;
      }

      .date-input {
        margin-bottom: $spacing1;
      }

      .qr-code-container {
        height: 220px;
        width: 220px;
        border-radius: 10px;
        border: 2px solid #d2d2d2;
        margin-top: $spacing2;
        display: flex;
        align-items: center;
        justify-content: center;

        .qr-code-img {
          width: 160px;
          height: 160px;
        }
      }

      .pet-medical-status {
        margin-top: 16px;

        .medical-info-item-wrapper {
          display: flex;
          align-items: center;
          width: 220px;
          margin-top: 4px;

          .status {
            min-width: 10px;
            width: 10px;
            height: 10px;
            border-radius: 5px;
            background-color: #7cf989;
            margin-right: 10px;

            &.status.status-not-verified {
              background-color: #ffad01;
            }

            &.status.status-declined {
              background-color: #ff5131;
            }
          }
        }
      }
    }
  }
}
