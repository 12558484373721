.tableWrapper {
  overflow-x: auto;

  .table {
    width: 100%;
    overflow-x: auto;

    thead {
      border-bottom: 1px solid #eee;

      tr {
        th {
          padding: 12px 6px;
          color: #222;
          min-width: 100px;
          font-weight: normal;
        }
      }
    }

    tbody {
      tr {
        border-bottom: 1px solid #eee;

        td {
          color: #666;
          padding: 12px 6px;
        }
      }
    }
  }

  .emptyMessage {
    text-align: center;
    padding: 16px;
    color: #666;
  }

  .sortIcons {
    margin-left: 4px;

    img {
      width: 18px;
    }
  }
}
