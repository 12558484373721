@import '../../assets/scss/variables.scss';

.private-layout {
    display: flex;
    height: 100vh;

    .close-menu-button {
        outline: none;
        border: none;
        padding: 0;
        cursor: pointer;
        background: none;
        margin-left: $spacing3;
        top: 36px;
        position: absolute;

        .close-icon {
            height: 30px;
        }
    }

    .menu-wrapper {
        width: 345px;
        min-width: 345px;
        overflow: auto;
        box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.15);
        z-index: 100;

        .logo-wrapper {
            display: flex;
            justify-content: center;
            margin-top: 25px;
            margin-bottom: 50px;

            .logo {
                max-height: 45px;
            }
        }

        .border-separator {
            border-top: 2px solid #eaeaea;
            margin-top: 20px;
            margin-right: 10px;
            margin-left: 10px;
        }

        .avatar-container {
            width: 110px;
            height: 110px;
            border-radius: 55px;
            box-shadow: 0px 0px 15px 3px rgba(0, 0, 0, 0.25);
            display: flex;
            align-items: center;
            justify-content: center;
            margin-left: auto;
            margin-right: auto;
            position: relative;

            .user-avatar-img {
                width: 100px;
                height: 100px;
                border-radius: 50px;
                background-position: center;
                background-size: cover;
            }

            .user-verified-img {
                width: 34px;
                height: 34px;
                position: absolute;
                bottom: 0px;
                right: 0px;
            }
        }

        .user-info-wrapper {
            display: flex;
            flex-direction: column;
            text-align: center;
            margin-top: $spacing2;

            .user-info-text {
                font-weight: 600;
                font-size: 18px;
            }

            .user-info-text-id {
                font-weight: 700;
            }
        }

        ul {
            list-style: none;
            margin-top: 30px;
            padding: 0;

            li {
                display: flex;
                justify-content: center;

                .list-item {
                    padding: 35px;
                    width: 100%;
                    height: 60px;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                    text-decoration: none;
                    font-size: 20px;
                    transition: background 0.2s;
                    align-items: center;
                    display: flex;
                    margin-right: 30px;
                    color: $textColor;
                    transition: all 0.2s;

                    &:hover {
                        background: #eee;
                    }

                    .item-icon-wrapper {
                        margin-right: 25px;
                        width: 30px;
                        height: 30px;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        .item-img {
                            max-width: 30px;
                            max-height: 30px;
                        }
                    }

                    &.selected {
                        background: $primary;
                        color: #ffff;
                    }

                    &.selected img {
                        filter: brightness(10);
                    }
                }
            }
        }

        @media screen and (max-width: 767px) {
            display: none;
            position: fixed;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            width: 100%;
            background: #fff;

            &.is-open {
                display: block;
            }
        }
    }

    .content-wrapper {
        min-height: 100vh;
        width: 100%;

        .topbar {
            display: flex;
            width: 100%;
            height: 100px;
            padding-left: $spacing3;
            padding-right: $spacing3;
            align-items: center;
            background-color: #fff;

            .hamburger-menu-img {
                height: 25px;
                cursor: pointer;
            }

            .page-title {
                font-size: 36px;
                font-weight: 600;
            }

            @media screen and (min-width: 767px) {
                .hamburger-menu-img {
                    display: none;
                }
                .close-menu-button {
                    display: none;
                }
            }

            @media screen and (max-width: 767px) {
                .page-title {
                    display: none;
                }
            }

            .user-options-wrapper {
                display: flex;
                flex: 1;
                align-items: center;
                justify-content: flex-end;

                .header-right-button {
                    padding: 0;
                    outline: none;
                    background: none;
                    border: none;
                    cursor: pointer;
                    margin-right: $spacing2;
                }

                .user-menu-img {
                    height: 40px;
                    cursor: pointer;
                }

                .dropdown-wrapper {
                    width: 160px;
                    padding: 5px 0;

                    a {
                        display: block;
                        font-size: 16px;
                        color: $textColor;
                        padding: 10px 16px;
                        text-decoration: none;

                        &:hover {
                            background-color: #f9f9f9;
                        }
                    }
                }
            }
        }

        > .page-content {
            height: calc(100vh - 100px);
            overflow: auto;
            background-color: #f8f8f8;
        }
    }
}
