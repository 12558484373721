@import '../../assets/scss/variables.scss';

.email-verification-modal {
  width: 450px;
  padding: $spacing3;

  .modal-title {
    font-size: 24px;
    color: $primary;
    margin: 0;
  }

  .modal-subtitle {
    font-size: 15px;
    color: #999;
  }

  .resend-code-wrapper {
    display: flex;
    justify-content: flex-end;
    margin-top: 5px;

    button {
      background: none;
      border: none;
      color: $primary;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }

      &[disabled] {
        color: #999;
        cursor: not-allowed;
      }
    }
  }
}
