@import '../../assets/scss/variables.scss';

.modalContainer {
  width: 750px;
  max-width: 90vw;
  background: $primary;
  box-shadow: 0px 0px 15px 15px rgba(0, 0, 0, 0.15);
  border-radius: 20px;
  padding: $spacing3;
  display: flex;
  flex-direction: column;
  align-items: center;

  .logoImg {
    max-width: 100%;
    max-height: 80px;
  }

  .contentWrapper {
    width: 100%;
    margin-top: 80px;
    margin-bottom: 80px;
  }
}
