@import '../../assets/scss/variables.scss';

.dashboard-page {
  padding: $spacing3;
  height: 100%;
  overflow: auto;

  .user-pet-text {
    font-weight: 700;
    color: #fff;
    flex: 1;
  }

  .title-container-bg {
    background-color: #ffad01;
    margin-bottom: 15px;
  }

  .exclamation-img {
    max-height: 24px;
  }

  .border-separator {
    border-top: 2px solid #eaeaea;
  }

  .add-new-pet {
    border-radius: 15px;
    overflow: hidden;
    background-color: #fff;
    height: 305px;
    width: 500px;
    max-width: 100%;
    border: 3px dashed #636363;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background 0.2s;

    .add-new-pet-img {
      max-width: 150px;
    }

    .add-new-pet-text {
      font-size: 24px;
    }

    &:hover {
      background: rgba(55, 190, 235, 0.07);
    }
  }

  @media screen and (max-width: 1536px) {
    .row {
      &.row-pets {
        display: flex;
        justify-content: center;
      }
    }
  }
}
