.actionButton {
  margin-left: 10px;
  border: none;
  background: none;
  cursor: pointer;

  &:first-child {
    margin-left: 0;
  }

  &:hover {
    img {
      opacity: 0.5;
    }
  }

  img {
    width: 26px;
  }
}

.emptyMessage {
  text-align: center;
  padding: 16px;
  color: #666;
}

.resultItem {
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 24px;
  margin-top: 16px;
  display: flex;
  align-items: center;

  &:first-child {
    margin-top: 0;
  }

  .resultItemContent {
    flex: 1;

    h3 {
      font-size: 24px;
      margin: 0;
    }

    h4 {
      font-size: 20px;
      margin: 0;
      font-weight: normal;
    }

    p {
      margin-top: 8px;
      margin-bottom: 0;
    }
  }

  .resultItemActions {
    display: flex;
    padding-left: 32px;
  }
}
