@import '../../assets/scss/variables.scss';

.confirm-modal {
  padding: $spacing3 65px;

  .pet-img {
    max-width: 100%;
    width: 280px;
    margin-right: $spacing4;
  }

  .content-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .heading-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;

    .title-text {
      font-size: 30px;
      color: $primary;
      text-align: center;
      margin-bottom: $spacing1;
    }

    .subtitle-text {
      font-size: 15px;
      margin-bottom: $spacing4;
      text-align: center;
    }
  }
}
